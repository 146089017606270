import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'

const TitleContainer = styled.div`
  width: 100%;

  ${({ theme: { pageMargins } }) => `
    padding: ${pageMargins?.sm}px;
  `}
`

const Title = styled.div`
  margin: 0 auto;

  ${({ theme: { colors, maxWidths, fontSize } }) => `
    color: ${colors?.darkGrey};
    max-width: ${maxWidths?.content}px;
    letter-spacing: .08em;

    & > h2 {
      font-size: 12px;
      line-height: 1.5em;
    }
    
    & > * {
      font-weight: 100;
      
      & > strong {
        font-weight: 400;
        color: ${colors?.black}
        font-size: ${fontSize.pageTitle?.mobile}px;
      }
    }
  `}

  ${({ theme: { media, fontSize, colors } }) => media.md`
    & > h2 {
      font-size: 24px;

      & > strong {
        color: ${colors?.black};
        font-size: ${fontSize.pageTitle?.desktop}px;
      }
    }
  `}
`

const PageTitle = ({ titleText }) => {
  const text = parse(titleText)

  return (
    <TitleContainer>
      <Title>{text}</Title>
    </TitleContainer>
  )
}

PageTitle.propTypes = {
  titleText: PropTypes.string.isRequired,
}

export default PageTitle
